<template>
  <div class="wrapper">
    <!-- <div class="spec">
      <el-button type="primary" size="small" icon="el-icon-plus" plain @click="addSpec">添加规格组</el-button>
      <div class="spec-item" v-for="(spec, i) in this.sourceAttribute" :key="i">
        <div class="spec-name">
          <span>
            <el-input @input="changeSpecName" v-model="spec.name" size="small" placeholder=""></el-input>
          </span>
          <span>
            <el-link type="primary" @click="deleteSpec(i)">删除规格组</el-link>
          </span>
        </div>
        <div class="spec-value">
          <span class="spec-value-input" v-for="(item, index) in spec.item" :key="index">
            <el-input size="small" placeholder="" v-model="spec.item[index]"></el-input>
            <i class="el-icon-error" @click="deleteSpecValue(i, index)"></i>
          </span>
          <span>
            <el-link style="margin-bottom: 10px" type="primary" @click="addSpecValue(i)">新增规格值</el-link>
          </span>
        </div>
      </div>
    </div> -->
    <div>
      <el-form-item label="商品规格：">
        <div v-if="status != 1" style="margin-bottom:10px;">
          <el-tag :key="tag" v-for="tag in dynamicTags" :closable="status != 1" :disable-transitions="false" @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加规格组</el-button>
        </div>
        <SkuForm ref="sku" :source-attribute="sourceAttribute" :structure="structure" :attribute.sync="attribute" :sku.sync="sku" :theme="2">
          <template #coverImage="slotProps">
            <div class="image-upload-container">
              <FileUpload v-if="status != 1" :name.sync="slotProps.row.coverImage" :url="getUrlById(slotProps.row.coverImage)"></FileUpload>
              <img v-else style="width:60px;height:60px;" :src="getUrlById(slotProps.row.coverImage)" alt="" />
            </div>
          </template>
          <template #rentPrice="slotProps">
            {{ (Number(slotProps.row.salePrice * slotProps.row.priceRate) / 100).toFixed(2) }}
          </template>
          <template #id="slotProps">
            {{ slotProps.row.id }}
          </template>
          <template #operation="slotProps" v-if="status == 1">
            <el-link type="primary" @click="editSku(slotProps.row)">库存</el-link>
          </template>
          <template #skuTitle="slotProps" v-if="status == 1">
            {{ slotProps.row.skuTitle }}
          </template>
          <template #officialPrice="slotProps" v-if="status == 1">
            {{ slotProps.row.officialPrice }}
          </template>
          <template #priceRate="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.priceRate) }}
          </template>
          <template #insurancePrice="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.insurancePrice) }}
          </template>
          <template #overflowPrice="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.overflowPrice) }}
          </template>
          <template #buyoutPrice="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.buyoutPrice) }}
          </template>
          <template #stockNum="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.stockNum) }}
          </template>
          <template #salePrice="slotProps" v-if="status == 1">
            {{ Number(slotProps.row.salePrice) }}
          </template>
        </SkuForm>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import SkuForm from 'vue-sku-form'
export default {
  name: 'Sku',
  components: { SkuForm },
  props: {
    status: {
      type: [String, Number],
      default: ''
    },
    skuAttrGroupList: {
      type: Array,
      default: () => []
    },
    skuFormList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sourceAttribute: [],
      structure: this.$route.params.id
        ? [
            {
              name: 'id',
              type: 'slot',
              label: '规格ID',
              batch: false
            },
            {
              name: 'skuTitle',
              type: 'input',
              label: '规格标题',
              batch: false,
              required: true
            },
            {
              name: 'officialPrice',
              type: 'input',
              label: '官方价格',
              batch: false,
              required: true
            },
            {
              name: 'salePrice',
              type: 'input',
              label: '零售价',
              batch: false,
              required: true
            },
            {
              name: 'priceRate',
              type: 'input',
              label: '价格系数(%)',
              batch: false,
              required: true
            },
            {
              name: 'rentPrice',
              type: 'slot',
              label: '总租金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'insurancePrice',
              type: 'input',
              label: '意外保',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'overflowPrice',
              type: 'input',
              label: '溢价金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'buyoutPrice',
              type: 'input',
              label: '买断金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'stockNum',
              type: 'input',
              label: '库存数量',
              defaultValue: 0,
              batch: false,
              required: true
            },
            {
              name: 'coverImage',
              type: 'slot',
              label: '规格封面',
              required: true
            }
          ]
        : [
            {
              name: 'skuTitle',
              type: 'input',
              label: '规格标题',
              batch: false,
              required: true
            },
            {
              name: 'officialPrice',
              type: 'input',
              label: '官方价格',
              batch: false,
              required: true
            },
            {
              name: 'salePrice',
              type: 'input',
              label: '零售价',
              batch: false,
              required: true
            },
            {
              name: 'priceRate',
              type: 'input',
              label: '价格系数(%)',
              batch: false,
              required: true
            },
            {
              name: 'rentPrice',
              type: 'slot',
              label: '总租金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'insurancePrice',
              type: 'input',
              label: '意外保',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'overflowPrice',
              type: 'input',
              label: '溢价金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'buyoutPrice',
              type: 'input',
              label: '买断金',
              defaultValue: 0,
              batch: false
            },
            {
              name: 'stockNum',
              type: 'input',
              label: '库存数量',
              batch: false,
              required: true
            },
            {
              name: 'coverImage',
              type: 'slot',
              label: '规格封面',
              required: true
            }
          ],
      attribute: [],
      sku: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  mounted() {
    // this.init()
  },
  computed: {
    skuList() {
      let list = []
      list = this.sku.map((sku, i) => {
        return {
          skuTitle: sku.skuTitle,
          officialPrice: sku.officialPrice,
          priceRate: sku.priceRate,
          salePrice: sku.salePrice,
          insurancePrice: sku.insurancePrice,
          overflowPrice: sku.overflowPrice,
          buyoutPrice: sku.buyoutPrice,
          stockNum: sku.stockNum,
          coverImage: sku.coverImage,
          rentPrice: sku.salePrice * sku.priceRate,
          skuAttrList: sku.sku.split(';').map((item, index) => {
            return {
              group: this.attribute[index]?.name,
              value: item
            }
          })
        }
      })
      return list
    }
  },
  watch: {
    status: {
      handler(newV, oldV) {
        if (newV) {
          this.structure = [
            {
              name: 'id',
              type: 'slot',
              label: '规格ID',
              batch: false
            },
            {
              name: 'skuTitle',
              type: 'slot',
              label: '规格标题',
              batch: false
            },
            {
              name: 'officialPrice',
              type: 'slot',
              label: '官方价格',
              batch: false
            },
            {
              name: 'salePrice',
              type: 'input',
              label: '零售价',
              batch: false,
              required: true
            },
            {
              name: 'priceRate',
              type: 'slot',
              label: '价格系数(%)',
              batch: false,
              required: true
            },
            {
              name: 'rentPrice',
              type: 'slot',
              label: '销售价',
              batch: false
            },
            {
              name: 'insurancePrice',
              type: 'slot',
              label: '意外保',
              batch: false
            },
            {
              name: 'overflowPrice',
              type: 'slot',
              label: '溢价金',
              batch: false
            },
            {
              name: 'buyoutPrice',
              type: 'slot',
              label: '买断金',
              batch: false
            },
            {
              name: 'stockNum',
              type: 'slot',
              label: '库存数量',
              batch: false
            },
            {
              name: 'coverImage',
              type: 'slot',
              label: '规格封面'
            },
            {
              name: 'operation',
              type: 'slot',
              label: '操作'
            }
          ]
        }
      }
    },
    skuAttrGroupList: {
      handler(newV, oldV) {
        if (newV) {
          this.sourceAttribute = this.skuAttrGroupList.map(item => {
            return {
              name: item.group,
              item: item.valueList
            }
          })
          this.attribute = this.sourceAttribute
          this.dynamicTags = this.skuAttrGroupList.map(item => {
            return item.group
          })
          this.init()
        }
      }
    },
    skuFormList: {
      handler(newV, oldV) {
        if (newV) {
          this.sku = this.skuFormList.map(item => {
            return {
              sku: this.sortSku(this.attribute, item.skuAttrList)
                .map(attr => {
                  return attr.value
                })
                .join(';'),
              skuTitle: item.skuTitle,
              officialPrice: item.officialPrice,
              salePrice: item.salePrice,
              priceRate: item.priceRate,
              insurancePrice: item.insurancePrice,
              overflowPrice: item.overflowPrice,
              buyoutPrice: item.buyoutPrice,
              rentPrice: item.rentPrice,
              stockNum: item.stockNum,
              coverImage: item.coverImage,
              id: item.id,
              coverImageUrl: item.coverImageUrl
            }
          })
          this.init()
        }
      }
    }
  },
  methods: {
    // 添加规格组
    addSpec() {
      let data = {
        name: '',
        item: []
      }
      this.sourceAttribute.push(data)
      this.init()
    },
    // 删除规格组
    deleteSpec(i) {
      this.sourceAttribute.splice(i, 1)
    },
    // 新增规格值
    addSpecValue(i) {
      this.sourceAttribute[i].item.push('')
    },
    // 删除规格值
    deleteSpecValue(i, index) {
      this.sourceAttribute[i].item.splice(index, 1)
      this.init()
    },
    changeSpecName() {
      this.init()
    },
    init() {
      this.$refs.sku.init()
    },
    handleClose(tag) {
      let attrIndex = ''
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
      this.sourceAttribute.forEach((item, i) => {
        if (item.name == tag) {
          attrIndex = i
        }
      })
      this.sourceAttribute = this.sourceAttribute.filter((attr, i) => this.dynamicTags.indexOf(attr.name) != -1)
      this.attribute = this.attribute.filter((attr, i) => this.dynamicTags.indexOf(attr.name) != -1)
      let arr = []
      arr = this.skuFormList.map(item => {
        return {
          skuTitle: item.skuTitle,
          officialPrice: item.officialPrice,
          insurancePrice: item.insurancePrice,
          overflowPrice: item.overflowPrice,
          buyoutPrice: item.buyoutPrice,
          rentPrice: item.rentPrice,
          stockNum: item.stockNum,
          coverImage: item.coverImage,
          id: item.id,
          coverImageUrl: item.coverImageUrl,
          goodsId: item.goodsId,
          priceRate: item.priceRate,
          salePrice: item.salePrice,
          skuAttrList: item.skuAttrList.filter((sku, i) => {
            if (this.dynamicTags.indexOf(sku.group) != -1) {
              return true
            }
          })
        }
      })
      this.sku = arr.map(item => {
        return {
          sku: item.skuAttrList
            .map(attr => {
              return attr.value
            })
            .join(';'),
          skuTitle: item.skuTitle,
          officialPrice: item.officialPrice,
          salePrice: item.salePrice,
          priceRate: item.priceRate,
          insurancePrice: item.insurancePrice,
          overflowPrice: item.overflowPrice,
          buyoutPrice: item.buyoutPrice,
          rentPrice: item.rentPrice,
          stockNum: item.stockNum,
          coverImage: item.coverImage,
          id: item.id,
          coverImageUrl: item.coverImageUrl
        }
      })
      this.init()
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        let data = {
          name: inputValue,
          item: []
        }
        this.dynamicTags.push(inputValue)
        this.sourceAttribute.push(data)
        this.init()
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    getUrlById(coverImage) {
      return this.skuFormList.find(item => item.coverImage == coverImage)?.coverImageUrl || ''
    },
    sortSku(attribute, skuAttrList) {
      let arr = []
      attribute.forEach((att, i) => {
        const item = skuAttrList.find((sku, index) => sku.group == att.name)
        if (item) {
          arr.push(item)
        }
      })
      return arr
    },
    // 库存
    editSku(id) {
      this.$emit('addStock', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  .spec {
    .spec-item {
      margin-bottom: 15px;
      .spec-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f4f5f9;
        padding: 10px;
      }
      .spec-value {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        background-color: #fbfbfb;
        .spec-value-input {
          position: relative;
          margin-right: 20px;
          margin-bottom: 10px;
          .el-icon-error {
            display: none;
            color: #858181;
            font-size: 18px;
            position: absolute;
            top: -10px;
            right: -10px;
            cursor: pointer;
          }
          &:hover .el-icon-error {
            display: block;
          }
        }
      }
    }
  }
  .sku-container .sku-check .theme-1 .item {
    width: 24% !important;
  }
}
</style>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
